.container {
  border-radius: 8px;
  position: relative;
  min-height: 48px;
  overflow: hidden;
  width: 100%;
}

.content {
  padding: 16px;
}

.indicator {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  width: 4px;
}

.title {
  font-weight: bold;
  z-index: 1;
}

.message {
  z-index: 1;
}

.bg {
  border-radius: 8px;
  height: 100%;
  opacity: 0.12;
  position: absolute;
  top: 0;
  width: 100%;
}
