@import "../../include-media.scss";

.container {
  align-items: center;
  display: flex;
  height: 96px;
  justify-content: space-between;
  padding: 0 32px;
}

.data {
  align-items: center;
  display: flex;
  justify-content: center;
}

.imageWrapper {
  display: flex;
  align-items: center;
  height: 64px;
  justify-content: center;
  width: 64px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
}

.text {
  margin: 2px 0;

  &.name {
    font-size: 16px;
    font-weight: 700;
  }

  &.type {
    font-size: 14px;
    font-weight: 600;
  }

  &.price {
    font-size: 16px;
    font-weight: 600;
  }
}

@include media("<=phone") {
  .container {
    align-items: center;
    background-color: #fff !important;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    flex-basis: calc(100% - 32px);
    flex-direction: column;
    height: auto;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 32px 0px;
  }

  .data {
    flex-direction: column;
    width: 100%;
  }

  .textWrapper {
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    margin-left: 0;
    width: 100%;
  }

  .text {
    width: 100%;
    text-align: center;
  }
}
