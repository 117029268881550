@import "../../../include-media.scss";

.container {
  align-items: center;
  background-color: #F9F9FA;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  overflow-y: scroll;
  padding-left: 48px;
  padding-top: 16px;
  position: fixed;
  transition: 0.2s ease all;
  width: 272px;
  z-index: 99;
}

.closeButton {
  left: -4px;
  height: 0;
  position: absolute;
  top: -4px;
  visibility: hidden;
  width: 0;
}

.logo {
  margin: 16px;
}

.top {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bottom {
  padding-bottom: 32px;
  width: 100%;
}

.name {
  padding: 8px;
}

@include media("<=tablet") {
  .container {
    left: -320px;

    &.display {
      left: 0
    }
  }
}