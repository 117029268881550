body {
  background-color: #F2F1F6;
  color: #000;
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scroll {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background: transparent;
  color: #000;
  cursor: pointer;
  outline: none;
  padding: 0;
}

h2 {
  margin: 0;
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em;
  color: #000;
}

li {
  list-style: none;
}

a {
  color: black;
  font-family: 'Lato', sans-serif;
  text-decoration: none;
}

a:hover {
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}

image {
  display: block;
}

#root {
  height: 100%;
}

.content-container {
  height: 100%;
}

.app-splash {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

button,
a,
input,
select,
div {
  color: #000;
}

.no-scroll {
  overflow: hidden;
}
