@import "../../../include-media.scss";

.container {
  overflow-y: scroll;
  padding-left: 320px;
  padding-top: 72px;
  padding-bottom: 56px;
}

.label {
  opacity: 0.4;
}

.spacer {
  margin: 16px 0;
}

.fileRowWrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@include media("<=tablet") {
  .container {
    padding-left: 48px;
  }
}
