.container {
  align-items: center;
  background-color: rgba(118, 118, 128, 0.12);
  border-radius: 8px;
  cursor: text;
  display: flex;
  height: 32px;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  width: 256px;

  &:focus-within {
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.08), 0 3px 6px rgba(0,0,0,0.13);
  }
}

.iconWrapper {
  align-items: center;
  display: flex;
  font-size: 15px;
  height: 32px;
  justify-content: center;
  opacity: 0.6;
  width: 32px;
}

.input {
  background-color: transparent;
  border: none;
  margin: 0;
  outline: none;
  padding: 0;
  width: 100%;
}
