.container {
  background-color: white;
  border-radius: 8px;
  opacity: 1;
  width: 480px;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
}

.title {
  font-size: 20px;
  font-weight: 600;
}

.description {
  font-size: 16px;
  opacity: 0.6;
  margin-top: 16px;
  margin-bottom: 8px;
  text-align: center;
}

.icon {
  font-size: 64px;
  color: #5CD8FF;
}

.iconWrapper {
  align-items: center;
  background-color: rgba(92, 216, 255, 0.2);
  border-radius: 48px;
  display: flex;
  height: 96px;
  justify-content: center;
  margin-bottom: 16px;
  width: 96px;
}
