.container {
  align-items: flex-start;
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 56px;
  position: fixed;
  transition: 0.2s ease all;
  width: 320px;
  z-index: 100;

  &.short {
    width: 48px;
  }
}