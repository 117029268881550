.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.text {
  font-size: 16px;
  flex-basis: 85%;
  text-align: start;
}
