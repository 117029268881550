.container {
  align-items: center;
  bottom: 0px;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100vw;
  z-index: 999;
}

.scrim {
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0px;
  cursor: default;
  height: 100%;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
}

.content {
  position: relative;
}
