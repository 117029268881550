.row {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 57px;
  justify-content: space-between;
  padding: 0 16px;
  position: relative;
  text-align: initial;
  width: calc(100% - 32px);

  &:hover {
    box-shadow: 0 1px 2px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.12);
    z-index: 10;

    .divider {
      opacity: 0;
    }
  }
}

.left, .right {
  align-items: center;
  flex-basis: 50%;
  display: flex;
}

.left {
  justify-content: flex-start;
}
.right {
  justify-content: flex-end;
}

.textPHWrapper {
  flex-basis: 50%;
  height: 24px;
}

.textPH {
  border-radius: 4px;
  background-color: #767680;
  height: 100%;
  width: calc(100% - 16px);
}

.statusPH {
  background-color: #767680;
  border-radius: 16px;
  height: 32px;
  width: 104px;
}

.divider {
  background-color: rgba(118,118,128,0.12);
  bottom: 0;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
}
