.row {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 57px;
  justify-content: space-between;
  padding: 0 16px;
  position: relative;
  text-align: initial;
  width: 100%;

  &:hover {
    box-shadow: 0 1px 2px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.12);
    z-index: 10;

    .divider {
      opacity: 0;
    }
  }
}

.left, .right {
  align-items: center;
  flex-basis: 50%;
  display: flex;
}

.left {
  justify-content: flex-start;
}
.right {
  justify-content: flex-end;
}

.name, .business {
  flex-basis: 50%;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.statusWrapper {
  align-items: center;
  border-radius: 16px;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0 16px;
}

.status {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.divider {
  background-color: rgba(118,118,128,0.12);
  bottom: 0;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
}
