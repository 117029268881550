.container {
  align-items: center;
  background-color: #E6E5EA;
  border-radius: 8px;
  display: flex;
  font-size: 16px;
  height: 32px;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 132px;
}

.button {
  background-color: black;
  bottom: 0px;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  height: 100%;
  position: absolute;
  transition: 0.2s ease all;
  width: 100%;

  &.active {
    left: 100%;
    overflow: hidden;
  }
}

.buttonCounter {
  margin: 0 16px;
  transition: 0.2 ease all;
}