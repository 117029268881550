@import "../../include-media.scss";

.container {
  align-items: center;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  overflow-y: scroll;
  padding-top: 16px;
  position: fixed;
  transition: 0.2s ease all;
  width: 320px;
  z-index: 99;
}

.top {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.logo {
  margin: 16px;
}

.sellerReference {
  display: flex;
  font-size: 16px;
  padding: 8px 16px;
  width: 100%;
}

.divider {
  background-color: rgba(0, 0, 0, 0.15);
  height: 1px;
  margin: 16px 0;
  width: calc(100% - 32px);
}

.bottom {
  padding-bottom: 32px;
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
  margin-top: 8px;
  width: calc(100% - 32px);
}

.name {
  align-self: flex-start;
  margin-left: 16px;
  margin-bottom: 8px;
}

.button {
  align-items: center;
  border-radius: 8px;
  display: flex;
  height: 40px;
  justify-content: center;
  width: calc(50% - 8px);

  &.contact {
    background-color: rgba(92, 216, 255, 0.12);
    color: #5CD8FF;
  }

  &.logout {
    background-color: rgba(118, 118, 128, 0.12);
    color: #767680;
  }
}

.chatIcon {
  font-size: 16px;
  margin-right: 8px;
}

.textButton {
  font-size: 14px;
}

.sellerRefButton {
  align-items: center;
  height: 48px;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  padding: 0 16px;
  width: calc(100% - 16px);
}

.closeButton {
  left: -4px;
  height: 0;
  position: absolute;
  top: -4px;
  visibility: hidden;
  width: 0;
}

.scrim {
  pointer-events: none;
  transition: 0.2s ease all;
  visibility: hidden;
  z-index: -10;
}

@include media("<=tablet") {
  .container {
    left: -320px;

    &.display {
      left: 0px;
    }
  }

  .closeButton {
    align-items: center;
    border-radius: 12px;
    background-color: rgba(0,0,0,0.12);
    display: flex;
    font-size: 18px;
    height: 24px;
    justify-content: center;
    left: auto;
    visibility: visible;
    right: 16px;
    top: 16px;
    width: 24px;
  }

  .scrim {
    background-color: rgba(0,0,0,0.4);
    bottom: 0;
    left: 0;
    pointer-events: all;
    position: fixed;
    right: 0;
    top: 0;
    visibility: visible;
    z-index: 98;
  }
}
