.bubble {
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 8px;
  bottom: 32px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  display: flex;
  font-family: sans-serif;
  font-size: 12px;
  justify-content: center;
  padding: 8px;
  position: absolute;
  width: 208px;
}

.iconWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.icon {
  background-color: rgba(118, 118, 128, 0.24);
  border-radius: 8px;
  font-size: 14px;
  margin-left: 8px;
  opacity: 0.5;
  padding: 2px;
}

.triangle {
  display: inline-block;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: #FFFFFF transparent transparent transparent;
  bottom: -8px;
  height: 0;
  position: absolute;
  right: 104px;
  width: 0;

  &.top {
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #FFFFFF transparent;
  }
}