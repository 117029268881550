@import "../../include-media.scss";

.container {
  background-color: #F2F1F6;
  height: 100vh;
  padding-left: 320px;
  padding-top: 56px;
}

@include media("<=tablet") {
  .container {
    padding-left: 0px;
  }
}

@include media("<=phone") {
  .container {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
