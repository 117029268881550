@import "../../include-media.scss";

.container {
  padding-left: 320px;
  padding-top: 72px;
  padding-bottom: 56px;
  overflow-y: scroll;
}

.inputSpacer {
  margin: 16px 0;
}

.fileSpacer {
  margin: 8px 0;
}

.question {
  margin-bottom: 8px;
  opacity: 0.6;
}

.fileRowWrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@include media("<=tablet") {
  .container {
    padding-left: 0px;
  }
}

@include media("<=smallTablet") {
  .fileRowWrapper {
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .smallDeviceSpacer {
    margin: 8px 0;
    flex-basis: 100%;
  }
}
