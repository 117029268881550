@import "../../include-media.scss";

.container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item {
  align-items: center;
  border: 1px solid rgba(118,118,128,0.6);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  transition: 0.2s ease all;

  &.selected {
    background-color: rgba(85,214,150,0.2);
    border-color: #55d696;

    .icon {
      color: #55d696;
    }

    .label {
      color: #55d696;
    }

    .selectedIcon {
      opacity: 1;
    }
  }
}

.icon {
  color: rgba(0,0,0,0.6);
  font-size: 48px;
  transition: 0.2s ease all;
}

.label {
  font-size: 18px;
  font-weight: 600;
  margin-top: 16px;
  transition: 0.2s ease all;
}

.selectedIcon {
  color: #55d696;
  font-size: 24px;
  opacity: 0;
  position: absolute;
  transition: 0.2s ease all;
  right: 8px;
  top: 8px
}

@include media("<=smallTablet") {
  .label {
    font-size: 14px;
    margin-top: 8px;
  }

  .selectedIcon {
    font-size: 18px;
    right: 4px;
    top: 4px;
  }

  .icon {
    font-size: 32px;
  }
}

@include media("<=smallPhone") {
  .label {
    font-size: 12px;
    margin-top: 4px;
  }

  .selectedIcon {
    font-size: 16px;
    right: 2px;
    top: 2px;
  }

  .icon {
    font-size: 24px;
  }
}
