@import "../../include-media.scss";

.button {
  align-items: center;
  border-radius: 8px;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  padding: 0 24px;
  position: relative;
  transition: 0.2s ease all;

  &.primary {
    background-color: rgba(85,214,150,0.2);
    color: #55D696;

    &:hover {
      background-color: rgba(85,214,150,0.3);
    }
  }

  &.secondary {
    border: 1px solid #55D696;
    color: #55D696;

    &:hover {
      background-color: rgba(85,214,150,0.2);
    }
  }

  &.secondaryBlack {
    border: 1px solid #000;
    color: #000;

    &:hover {
      background-color: rgba(0,0,0,0.05);
    }
  }

  &.tertiary {
    color: #55D696;

    &:hover {
      background-color: rgba(85,214,150,0.1);
    }
  }
}

.icon {
  margin-right: 4px;
}

.badge {
  align-items: center;
  background-color: #55D696;
  border-radius: 12px;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: -8px;
  top: -6px;
  width: 24px;
}

@include media("<=420px") {
  .button {
    font-size: 12px;
    padding: 0 8px;
    height: 32px;
  }
}
