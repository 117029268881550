.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 16px;
  position: relative;
}

.imgWrapper {
  align-items: center;
  background-color: rgba(118, 118, 128, 0.12);
  border-radius: 8px;
  display: flex;
  height: 88px;
  justify-content: center;
  min-width: 88px;
  position: relative;
  width: 88px;
}

.img {
  height: 64px;
  width: 64px;
  object-fit: cover;
}

.counter {
  align-items: center;
  background-color: #767680;
  border-radius: 24px;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: -8px;
  top: -8px;
  width: 24px;
}

.count {
  color: white;
  font-size: 16px;
}

.textWrapper {
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  margin-left: 8px;
  width: 100%;
}

.priceWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.price {
  margin-right: 8px;
}

.currency {
  align-self: flex-end;
  font-size: 8px;
}

.dividerWrapper {
  display: flex;
  flex-direction: row-reverse;
}

.divider {
  background-color: rgba(0, 0, 0, 0.15);
  bottom: 0px;
  height: 1px;
  right: 16px;
  position: absolute;
  width: calc(100% - 136px);
}
