.label {
  width: 100%;
}

.wrapper {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 8px;
  display: flex;
  height: 24px;
  padding: 24px 16px;
  padding-bottom: 8px;
  position: relative;
  transition: 0.2s ease all;

  &:focus-within {
    background-color: var(--tAccent05);
    border-color: #55d696;

    .icon {
      opacity: 1;
    }

    .inputTitle {
      color: #55d696;
      font-size: 12px;
      opacity: 1;
      top: 14px;
    }
  }

  &.error {
    border-color: #FF3B30;

    .inputTitle {
      color: #FF3B30;
    }
  }
}

.selectTitle {
  font-size: 14px;
  font-weight: 600;
  opacity: 0.6;
  position: absolute;
  top: 16px;
  transform: translate(0, -50%);
  transition: 0.3s ease all;

  &.withValue {
    font-size: 12px;
    top: 14px;
  }
}

.icon {
  font-size: 16px;
  position: absolute;
  right: 16px;
}

.select {
  align-items: flex-end;
  border: none;
  border-radius: 8px;
  background-color: rgba(0,0,0,0.05);
  display: flex;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.025em;
  height: 100%;
  outline: none;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100%;
  padding: 24px 16px;
  padding-bottom: 8px;
  -moz-appearance: none;
  -webkit-appearance: none;
}
