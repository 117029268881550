.container {
  width: 100%;
}

.wrapper {
  align-items: center;
  background-color: rgba(0,0,0,0.05);
  border: 1px solid transparent;
  border-radius: 8px;
  display: flex;
  padding: 24px 16px;
  padding-bottom: 8px;
  position: relative;
  transition: 0.2s ease all;

  &:focus-within {
    background-color: var(--tAccent05);
    border-color: #55d696;

    .icon {
      opacity: 1;
    }

    .inputTitle {
      color: #55d696;
      font-size: 12px;
      opacity: 1;
      top: 14px;
    }
  }

  &.error {
    border-color: #FF3B30;

    .inputTitle {
      color: #FF3B30;
    }
  }
}

.inputTitle {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  opacity: 0.6;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  transition: 0.3s ease all;

  &.withValue {
    font-size: 12px;
    top: 14px;
  }
}

.icon {
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: center;
  opacity: 0.5;
}

.input {
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.025em;
  outline: none;
  width: 100%;

  &.password {
    letter-spacing: 0.25em;
  }

  &::selection {
    background-color: rgba(85,214,150,0.2);
  }
}

.visibilityButton {
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: center;
  opacity: 0.6;
}
