@import "../../include-media.scss";

.container {
  display: flex;
  overflow: hidden;
  padding-left: 320px;
  padding-top: 56px;
  padding-bottom: 56px;
  position: relative;
}

.billingData {
  border-right: 1px solid rgba(0,0,0,0.15);
  display: flex;
  flex-basis: 53%;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0 16px;
}

.inputSpacer {
  margin: 16px 0;
}

.finishPurchase {
  align-self: flex-end;
  background-color: #000000;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  height: 48px;
  margin-top: 32px;
  min-height: 48px;
  padding: 0 16px;
}

.productData {
  align-items: center;
  flex-basis: 43%;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
}

.products {
  position: relative;
}

.text {
  color: #000000;
  font-size: 24px;
  font-weight: 700;

  &.headers {
    margin-top: 24px;
  }
}

.amounts {
  flex-direction: column;
  display: flex;
  padding: 16px 16px;
  position: relative;
}

.wrapper {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: space-between;
}

.totalText {
  font-size: 16px;
  font-weight: 400;
}

.price {
  font-size: 16px;
  font-weight: 400;
  margin-right: 8px;
}

.currency {
  align-self: flex-end;
  font-size: 8px;
}

.priceWrapper {
  display: flex;
}

.total {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding: 16px;
}

.divider {
  background-color: rgba(0, 0, 0, 0.15);
  bottom: 0px;
  height: 1px;
  left: 16px;
  position: absolute;
  width: calc(100% - 32px);
}

@include media("<=tablet") {
  .container {
    padding-left: 0;
  }

  .productData {
    width: 40%;
  }
}

@include media("<=phone") {
  .container {
    flex-direction: column-reverse;
    padding-left: 0px;
  }

  .productData {
    width: 90%;
    margin-left: 24px;
  }
}
