@import "../../include-media.scss";

.container {
  display: flex;
  justify-content: space-between;
}

.info {
  flex-basis: 35%;
  padding: 0 16px;
  position: relative;
}

.form {
  border-left: 1px solid rgba(0,0,0,0.15);
  flex-basis: 65%;
  padding: 0 16px;
  position: relative;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.note {
  font-size: 12px;
  font-weight: 300;
  max-width: 560px;
}

.dividerSpacer {
  margin: 16px 0;
}

.divider {
  background-color: rgba(0,0,0,0.15);
  bottom: 0;
  height: 1px;
  left: 16px;
  position: absolute;
  width: calc(100% - 32px);
}

.noteSpacer {
  margin: 8px 0;
}

.topSpacer {
  margin-top: 16px;
}


@include media("<=phone") {
  .info {
    flex-basis: 0%;
    overflow: hidden;
    height: 0;
    padding: 0;
    visibility: hidden;
    width: 0;
  }

  .form {
    flex-basis: 100%;
  }
}
