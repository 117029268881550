.container {
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  display: flex;
  height: calc(80px - 32px);
  margin-bottom: 16px;
  padding: 16px;
  position: absolute;
  right: -432px;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  width: calc(400px - 32px);

  &.display {
    right: 16px;
  }

  &.dismissing {
    opacity: 0;
    right: -432px;
  }

  &:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
}

.iconWrapper {
  align-items: center;
  border-radius: 8px;
  display: flex;
  font-size: 32px;
  height: 48px;
  justify-content: center;
  margin-right: 8px;
  min-width: 48px;
}

.title {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;

  &.withTitle {
    font-size: 12px;
    margin-top: 2px;
  }
}
