@import "../../include-media.scss";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px;
  width: 35%;
}

.topText {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 16px;
  text-align: center;
}

.title {
  color: #000;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
}

.contactWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 32px;
}

.contactQuestion {
  font-weight: 600;
}

.contactLink {
  color: #55d696;
  font-weight: 600;
}

.note {
  align-self: flex-start;
  font-size: 12px;
  opacity: 0.6;
  margin-top: 8px;
}

.termsConditions {
  align-items: center;
  align-self: center;
  display: flex;
  font-size: 12px;
  margin-top: 32px;
}

.checkBox {
  margin-right: 8px;
  height: 16px;
  width: 16px;
}

.textWrapper {
  display: flex;
  align-items: center;
}

.termsButton {
  background-color: transparent;
  color: #55d696;
  font-size: 12px;
}

.footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.primaryButton {
  background-color: #000;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 0 32px;

  &.active {
    margin-top: 32px;
  }
}

.switchButton {
  font-size: 12px;
  font-weight: 600;
}

.switchHighlight {
  color: #55d696;
}

.featureTitle {
  color: #55d696;
  font-size: 24px;
  font-weight: bold;
  margin-top: 48px;
  margin-bottom: 16px;
  text-align: center;
}

.features {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.feature {
  align-items: center;
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  justify-content: center;
}

.featureIcon {
  align-items: center;
  background-color: rgba(85,214,150, 0.2);
  border-radius: 40px;
  display: flex;
  font-size: 32px;
  height: 80px;
  justify-content: center;
  margin-bottom: 16px;
  width: 80px;
}

.iconWrapper {
  align-items: center;
  color: #55d696;
  display: flex;
  justify-content: center;
}

.featureDescription {
  font-weight: 300;
  text-align: center;
}

.inputSpacer {
  margin: 8px 0;
}

.logo {
  left: 32px;
  position: absolute;
  top: 32px;
  width: 160px;
}

@include media("<=tablet") {
  .content {
    width: 60%;
  }

  .logo {
    left: 0;
    min-height: 65px;
    position: relative;
    top: 0;
    width: 160px;
  }
}

@include media("<=phone") {
  .content {
    width: 80%;
  }
}

@include media("<=smallPhone") {
  .content {
    width: 90%;
  }
}
