.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-right: 8px;
}

.iconWrapper {
  align-items: center;
  background-color: rgba(85, 214, 150, 0.2);
  border-radius: 24px;
  color: #55D696;
  display: flex;
  justify-content: center;
  margin-right: 4px;
  height: 24px;
  width: 24px;
}

.text {
  font-size: 12px;
}