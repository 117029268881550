.row {
  align-items: center;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding: 0 16px;
  position: relative;
  text-align: initial;
  width: calc(100% - 32px);
  z-index: 1;
}

.left, .right {
  align-items: center;
  flex-basis: 50%;
  display: flex;
}

.left {
  justify-content: flex-start;
}
.right {
  justify-content: flex-end;
}

.name, .business {
  flex-basis: 50%;
  font-weight: 400;
}

.name, .business, .status {
  font-size: 14px;
}
