.button {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 32px;
  width: calc(100% - 32px);
  margin: 0 16px;
  margin-bottom: 16px;

  &.short {
    align-self: center;
    width: 32px;
  }
}

.iconWrapper {
  background-color: rgba(118, 118, 128, 0.12);
  border-radius: 16px;
  color: #767680;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: 0.2s ease all;
  width: 32px;

  &.active {
    background-color: rgba(85, 214, 150, 0.2);
    border-radius: 8px;
    color: #55D696;
  }
}

.iconWrapper:hover {
  border-radius: 8px;
}

.icon {
  font-size: 16px;
}

.text {
  font-size: 16px;
  font-weight: 500;
  margin-left: 8px;
}