.row {
  align-items: center;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  height: 48px;
  justify-content: flex-end;
  padding: 0 16px;
  width: calc(100% - 32px);
}

.pages {
  font-size: 14px;
  margin-right: 8px;
}

.paginationButton {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 32px;
  font-size: 18px;
  justify-content: center;
  transition: 0.2s ease all;
  width: 32px;

  &:hover {
    background-color: rgba(118,118,128,0.12);
  }

  &.disabled {
    background-color: transparent;
    cursor: default;
    opacity: 0.4;
  }
}

