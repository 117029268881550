.container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.chips {
  display: flex;
  justify-content: space-evenly;
  margin-top: 16px;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  display: flex;
  flex-direction: column;
  width: 448px;
  padding: 32px;
  position: relative;
}

.title {
  font-weight: 600;
  font-size: 24px;
  text-align: center;
}

.description {
  font-weight: 300;
  margin: 16px 0;
  text-align: center;
}

.button {
  align-items: center;
  align-self: center;
  background-color: #000;
  border-radius: 8px;
  color: #fff;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  margin-top: 16px;
  position: relative;
  width: 256px;

  .text {
    z-index: 1;
  }

  .scrim {
    background-color: #55d696;
    border-radius: 8px;
    height: 100%;
    left: 0;
    position: absolute;
    transition: 0.6s ease width;
    width: 0;
  }

  &:hover {
    .icon {
      right: 8px;
    }

    .scrim {
      width: 100%;
    }
  }
}

.icon {
  position: absolute;
  right: 16px;
  transition: 0.4s ease right;
  z-index: 1;
}

.horizontalScrollWrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
}

.inputWrapper {
  flex-shrink: 0;
  width: 100%;
}

.backButton {
  align-items: center;
  border-radius: 8px;
  display: flex;
  font-size: 18px;
  left: 32px;
  height: 32px;
  justify-content: center;
  position: absolute;
  top: 32px;
  width: 32px;
  transition: 0.2s ease all;

  &:hover {
    background-color: rgba(118,118,128,0.14);
  }
}
