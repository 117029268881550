@import "../../../include-media.scss";

.container {
  display: flex;
  flex-direction: column;
  padding-left: 336px;
  padding-top: 72px;
  padding-bottom: 56px;
  padding-right: 16px;
  overflow-y: hidden;
}

@include media("<=tablet") {
  .container {
    padding-left: 0;
  }
}
