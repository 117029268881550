.container {
  align-items: center;
  background-color: rgba(118,118,128,0.12);
  border-radius: 8px;
  display: flex;
  height: 48px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.highlight {
  background-color: #FAFAFD;
  border-radius: 8px;
  left: 2px;
  position: absolute;
  height: 44px;
  transition: 0.3s ease left;
  top: 2px;
}
