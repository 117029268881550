.container {
  &.question {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.value {
  font-size: 16px;

  &.yes {
    color: #55D696;
    font-weight: 500;
  }

  &.no {
    color: #FF3B30;
    font-weight: 500;
  }
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
}