.container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  flex-direction: column;
  display: flex;
  padding: 32px;
  width: 512px;
}

.inputSpacer {
  margin: 8px 0;
}

.primaryButton {
  align-self: center;
  background-color: #000;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  margin-top: 16px;
  padding: 0 32px;

  &.active {
    margin-top: 32px;
  }
}

.divider {
  background-color: rgba(118,118,128,0.12);
  height: 1px;
  margin: 16px 0;
  width: 100%;
}

.message {
  color: rgba(118,118,128,0.6);
  cursor: default;
  font-size: 14px;
  text-align: start;

  span {
    color: #55d696;
  }
}

.logo {
  align-self: center;
  margin-bottom: 32px;
}


