.container {
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-bottom: 16px;
  position: relative;
  max-height: 50vh;
  overflow-y: scroll;
  width: 640px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.footer {
  align-items: center;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  height: 56px;
  justify-content: flex-end;
  min-height: 56px;
  padding: 0 32px;
}

.primaryButton {
  background-color: #FF3B30;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  height: 32px;
  padding: 0 16px;
}

.tertiaryButton {
  border-radius: 8px;
  color: #767680;
  font-weight: 600;
  height: 32px;
  padding: 0 16px;
}
