.container {
  align-items: center;
  display: flex;
  overflow-x: scroll;
  padding: 16px 0;
  width: 100%;
}

.chipSpacer {
  margin: 0 8px;
}
