@import "../../include-media.scss";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 56px;
  justify-content: space-between;
  padding-left: 321px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
}

.nav {
  align-items: center;
  border-bottom: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  height: 100%;
  width: calc(100% - 32px);
}

.left {
  align-items: center;
  display: flex;
}

.navActions {
  align-items: center;
  display: flex;
}

.navButton {
  align-items: center;
  border-radius: 8px;
  display: flex;
  font-size: 24px;
  height: 40px;
  justify-content: center;
  transition: 0.2s ease all;
  width: 40px;

  &:hover {
    background-color: rgba(118,118,128,0.12);
  }

  &.menu {
    height: 0;
    visibility: hidden;
    width: 0;
  }
}

.title {
  font-size: 20px;
  font-weight: bold;
}

.verticalSpacer {
  margin: 0 8px;
}

@include media("<=tablet") {
  .container {
    padding-left: 0px;
  }

  .navButton {
    &.menu {
      height: 40px;
      visibility: visible;
      width: 40px;
    }
  }
}

@include media("<=420px") {
  .title {
    font-size: 16px;
  }

  .navButton {
    font-size: 20px;
    height: 32px;
    width: 32px;
  }
}
