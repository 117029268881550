@import "../include-media.scss";

.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@include media("<=smallTablet") {
  .container {
    flex-direction: column;
  }

  .verticalSpacer {
    margin: 8px 0px;
  }
}
