.container {
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-bottom: 16px;
  position: relative;
  max-height: 50vh;
  overflow-y: scroll;
  width: 640px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.sectionTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.description {
  color: #767680;
  font-size: 14px;
  margin: 16px 0;
}

.divider {
  background-color: rgba(118,118,128,0.12);
  height: 1px;
  margin: 16px 0;
  min-height: 1px;
  width: 100%;
}

.spacer {
  margin: 8px 0;
}

.footer {
  align-items: center;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  height: 56px;
  justify-content: flex-end;
  min-height: 56px;
  padding: 0 32px;
}

.primaryButton {
  background-color: #55d696;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  height: 32px;
  padding: 0 16px;
}

.tertiaryButton {
  border-radius: 8px;
  color: #55d696;
  font-weight: 600;
  height: 32px;
  padding: 0 16px;
}

.titleWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.createBranchButton {
  border-radius: 12px;
  background-color: rgba(85, 214, 150, 0.2);
  color: #55d696;
  font-weight: 600;
  height: 24px;
  padding: 0 16px;
}
