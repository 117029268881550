.container {
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: space-between;
  min-height: 48px;
  padding: 0 16px;
  position: relative;
  width: calc(100% - 32px);
}

.hiddenInput {
  height: 0;
  position: absolute;
  visibility: hidden;
  width: 0;
}

.transferIconWrapper {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  position: relative;
  width: 24px;
}

.uploadIcon {
  color: #767680;
  position: absolute;
  transition: 0.2s ease all;

  &.transfering {
    animation: waiting 3s linear infinite;
  }

  &.error {
    opacity: 0;
  }

  &.done {
    opacity: 0;
  }
}

.errorIcon {
  color: #FF3B30;
  opacity: 0;
  position: absolute;
  transition: 0.2s ease all;

  &.error {
    opacity: 1;
  }
}

.doneIcon {
  color: #55d696;
  opacity: 0;
  position: absolute;
  transition: 0.2s ease all;

  &.done {
    opacity: 1;
  }
}

.progressWrapper {
  background-color: rgba(38, 155, 250, 0.2);
  height: 100%;
  left: 0;
  position: absolute;
  transition: 0.2s ease all;
  width: 50%;
}

.progressLine {
  background-color: #269BFA;
  bottom: 8px;
  height: 1px;
  position: absolute;
  width: 100%;
}

@keyframes waiting {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
