@import "../../include-media.scss";

.container {
  background-color: #fff;
  bottom: -56px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  display: flex;
  flex-direction: column;
  font-weight: 600;
  height: 56px;
  left: 50%;
  justify-content: center;
  max-width: 512px;
  padding: 0 16px;
  position: fixed;
  transform: translate(-50%);
  transition: 0.2s ease all;

  &.visible {
    bottom: 16px;
  }
}

.actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
}

.saveButton {
  background: rgba(85,214,150,0.2);
  border-radius: 4px;
  color: #55d696;
  font-weight: 600;
  height: 24px;
  padding: 0 8px;
  transition: 0.2s ease all;

  &:hover {
    background: rgba(85,214,150,0.2);
  }
}

.discardButton {
  border-radius: 4px;
  color: #000;
  font-weight: 600;
  height: 24px;
  padding: 0 8px;
  transition: 0.2s ease all;
}

.question {
  font-size: 12px;
  text-align: center;
  width: 100%;
  white-space: nowrap;
}

@include media("<=384px") {
  .container {
    bottom: -80px;
    height: 80px;
    min-width: 80%;

    &.visible {
      bottom: 8px;
    }
  }

  .question {
    white-space: normal;
  }
}
