@import "../../../include-media.scss";

.container {
  background-color: white;
  border-radius: 8px;
  opacity: 1;
  width: 480px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px;
}

.footer {
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  padding-bottom: 16px;
}

.input {
  margin-top: 8px;
}

.header {
  align-self: flex-start;
  font-size: 16px;
  font-weight: 600;
}

.text {
  align-self: flex-start;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  text-align: start;
}

.divider {
  border: 1px solid rgba(118, 118, 128, 0.12);
  margin-top: 16px;
  width: 100%;
}

.buttons {
  align-items: center;
  align-self: flex-end;
  display: flex;
}

.button {
  font-size: 16px;
  font-weight: 600;
  margin: 0 8px;
}

@include media("<=phone") {
  .container {
    width: 320px;
  }
}

@include media("<=smallPhone") {
  .container {
    width: 256px;
  }
}
