.container {
  align-items: center;
  height: 48px;
  display: flex;
  overflow: hidden;
  padding: 0 8px;
  position: relative;
  width: calc(100% - 16px);

  &.active {
    background-color: rgba(118, 118, 128, 0.12);
    border-radius: 8px;
  }
}

.wrapper {
  align-items: center;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  transition: 0.2s ease all;
  width: 100%;
}

.text {
  font-weight: 500;
  font-size: 16px;

  &.active {
    margin-left: 8px;
  }
}