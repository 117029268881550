.chip {
  align-items: center;
  background: rgba(118,118,128,0.2);
  border-radius: 16px;
  display: flex;
  height: 32px;
  font-size: 14px;
  padding: 0 16px;
  position: relative;

  &.active {
    color: #55d696;
    background-color: rgba(84, 214, 150, 0.2);

    .indicator {
      background-color: #55d696;
      color: #fff;
    }
  }
}

.indicator {
  align-items: center;
  background-color: rgba(0,0,0,0.2);
  border-radius: 8px;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-left: 8px;
  width: 16px;
}
