.actionContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 56px;
  padding-right: 16px;
  position: fixed;
  right: 0;
  top: 0,;
  z-index: 12;
}

.spacer {
  margin: 0 8px;
}
