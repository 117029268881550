.chip {
  border: 1px solid rgba(118, 118, 128, 0.6);
  border-radius: 8px;
  font-size: 16px;
  flex-shrink: 0;
  height: 32px;
  padding: 0 16px;

  &.selected {
    background-color: rgba(85, 214, 150, 0.2);
    border-color: #55d696;
    color: #55d696;
  }
}
